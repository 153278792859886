<template>
  <div v-if="!$store.getters.GET_CLIENT_LOGINED">
    <div class="dialogTitle error">{{ error }}</div>
    <div class="dialogForm">
      <v-form class="loginForm" @submit.prevent="loginUser">
        <v-text-field
          autocomplete="username"
          class="loginInput my-2"
          v-model="name"
          type="text"
          label="Логин"
          outlined
          hide-details
          rounded
        ></v-text-field>
        <v-text-field
          v-model="password"
          rounded
          outlined
          hide-details
          autocomplete="new-password"
          class="loginInput my-2"
          label="Пароль"
          type="password"
        ></v-text-field>
          <button type="submit" class="btn buttonLogin" :disabled="!isAllowed">
           Войти <span class="material-icons"> arrow_forward </span>
          </button>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    name: "",
    password: "",
    error: "",
    show: true,
  }),
  computed: {
    isAllowed() {
      if (this.name && this.password) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(["LOG_IN"]),
    loginUser() {
      this.LOG_IN({
        name: this.name,
        password: this.password,
      }).then((el) => {
        if (el.status == true) {
          this.$store.commit("SET_CLIENT_LOGIN", true);
          this.$store.dispatch("GET_SERVICE");
        } else {
          this.error = "Проверьте корректность ввода";
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/loginUser.scss";
</style>
