<template>
  <div class="tabItemWrapper">
    <div
      class="showPopup hide"
      id="lightbox"
      @click="hide"
      ref="imagePopup"
    >
    <img :src="imageUrl" class="bigPick" alt="">
    </div>
    <v-tabs-items class="mt-2" v-model="tab">
      <v-tab-item v-for="(item, index) in tabList" :key="index">
        <v-card-text
          v-for="(it, id) in item.list"
          :key="id"
          class="d-flex flex-column cardText pa-3 my-6 mt-0"
        >
          <div class="counter">{{ id + 1 }}</div>
          <h2 class="tabTitle">{{ it.header }}</h2>
          <div v-for="(item, index) in it.content" :key="index">
            <p class="textContent">{{ item.text }}</p>
            <div v-if="item.link" class="d-flex justify-center">
              <a class="linkContent" :href="item.link">{{ item.link }}</a>
            </div>

            <img
              v-if="item.url"
              class="imageContent"
              :src="imageLink(item.url)"
              @click="show(item.url)"
            />
          </div>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
export default {
  props: ["tab", "tabList"],
  data() {
    return {
      visible: false,
      imageUrl:''
    };
  },
  methods: {
    imageLink(url) {
      return require(`../assets/images/${url}.png`);
    },
    show(str) {
      this.visible = true;
      console.log("show");
      this.imageUrl = this.imageLink(str) 
      this.$refs.imagePopup.classList = " showPopup ";
    },
    hide() {
      this.visible = false;
      this.$refs.imagePopup.classList = "showPopup hide";
    },
    onKeydown(e) {
      if (this.visible) {
        switch (e.key) {
          case " ":
            e.preventDefault();
            break;
          case "Escape":
            this.hide();
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tabItemWrapper{
  position: relative;
}
.cardText {
  border-radius: 10px !important;
  width: 100%;
  border: 1px solid #e5e5e5 !important;
  margin: 0 auto;
  position: relative;
  .counter {
    position: absolute;
    left: 5px;
    top: 5px;
    background: #ff7323;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-weight: 300;
  }
}

.tabTitle {
  user-select: none;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #666666;
  margin: 15px 0;
  span {
    color: #e74c3c;
  }
}
.textContent {
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  color: #666666;
}
.linkContent {
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff7323;
  padding: 4px;
  margin: 0 auto;
}
.imageContent {
  max-width: 100%;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 5px 16px -7px rgba(34, 60, 80, 0.41);
  }
}
.bigPick {
  position: absolute;
  top: 50%;
  max-width: 90vw;
  max-height: 90vh;
  height: 100%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.showPopup {
  display: block;
  &.hide {
    display: none;
  }
}
#lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.3); /* Black w/ opacity */
  z-index: 9999;
}
@media screen and (max-width: 500px) {
  .tabTitle {
    margin: 10px 0;
    font-size: 17px;
  }
  .cardText {
    .counter {
      left: 3px;
      top: 3px;
      font-size: 10px;
      width: 14px;
      height: 14px;
      line-height: 14px;
    }
  }
  .textContent {
    font-size: 12px;
    line-height: 13px;
  }
}
</style>