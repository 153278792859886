import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);


export default new Vuetify({
    theme:{
        themes: {
            light: {
                primary: '#FF7323'
            }
        }
    }
});
