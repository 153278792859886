<template>
  <div class="sign-in-group">
    <v-tooltip bottom v-if="!loggedIn">
      <template v-slot:activator="{ on, attrs }">
        <div
          @click="login"
          v-bind="attrs"
          v-on="on"
          ref="signingapi"
          class="btn btn-sign-in "
        >
          <span class="mx-auto">Войти</span>
        </div>
      </template>
      <span>Не нажимается? Hard reload: ctrl+shift+r</span>
    </v-tooltip>
    <div v-else class="logoutBtn btn-sign-in" @click="logout">
      <img class="googleAva" :src="ava" alt="avatar" />
      <span class="d-desctop">Выйти</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "GoogleSigninButton",
  data: function() {
    return {
      loggedIn: false,
      avatar: "",
    };
  },
  computed: {
    ava() {
      const urlava =
        localStorage.getItem("profileImage") ||
        "https://picsum.photos/500/300?image=20";

      return urlava;
    },
  },
  mounted() {
    if (
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("192.168")
    ) {
      console.log("test mode, no auth");
      this.loggedIn = true;
      return;
    }
    const cookies = document.cookie;
    if (cookies.indexOf("auth=") >= 0 && cookies.indexOf("auth=false") < 0) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }

    if (this.checkIfLoggedIn() === false) {
      window.alert(
        "Вы не вошли в аккаунт GoMobile, или ваша сессия истекла. Чтобы продолжить, нажмите кнопку Sign In в верхнем правом углу."
      );
    }
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.defer = true;
    script.onload = this.login;
    const meta = document.createElement("meta");
    meta.name = "google-signin-client_id";
    meta.content =
      "550459764446-n80kf4io2tqgei9cehu26ab8chjo2pdf.apps.googleusercontent.com";
    document.head.appendChild(meta);
    document.head.appendChild(script);
  },
  methods: {
    login() {
      if (!window.gapi) {
        location.reload(true);
      }
      window.gapi.load("auth2", () => {
        const auth2 = window.gapi.auth2.init({
          // eslint-disable-next-line @typescript-eslint/camelcase
          client_id:
            "550459764446-n80kf4io2tqgei9cehu26ab8chjo2pdf.apps.googleusercontent.com",
          cookiepolicy: "single_host_origin",
        });
        //console.log("Button found: ", this.$refs.signingapi);
        auth2.attachClickHandler(
          this.$refs.signingapi,
          {},
          (googleUser) => {
            const profile = googleUser.getBasicProfile();
            localStorage.setItem("profileImage", profile.getImageUrl());
            this.sendPostAsync(
              "https://cooker.gomobile.ru/cooker",
              {
                Email: profile.getEmail(),
                Token: googleUser.getAuthResponse().id_token,
                Action: "create",
              },
              true
            ).then((response) => {
              console.log("Responce: " + response.responseText);
              document.location.reload(true);
            });
          },
          (error) => console.log(error)
        );
      });
    },
    logout() {
      this.sendPostAsync(
        "https://cooker.gomobile.ru/cooker",
        {
          Email: "remove",
          Token: "remove",
          Action: "remove",
        },
        true
      ).then((response) => {
        console.log("Responce: " + response.responseText);
        // document.location.reload(true);
        location.reload(true);
      });
    },
    checkIfLoggedIn() {
      this.sendPostAsync(
        "https://cooker.gomobile.ru/verify",
        {
          Token: this.getCookie("auth"),
        },
        false
      ).then((response) => {
        this.loggedIn = response.responseText === "OK";
        return this.loggedIn;
      });
    },
    sendPostAsync(url, body, withCredentials) {
      return new Promise(function(resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
        xhr.withCredentials = withCredentials;
        xhr.onload = function() {
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        };
        xhr.onerror = function() {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.send(JSON.stringify(body));
      });
    },
    getCookie(cname) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
});
</script>

<style lang="scss">
.sign-in-group {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-align: center;
}
.googleAva {
  width: 45px;
  height: 45px;
  margin: 0 5px;
  border-radius: 50%;
}
.logoutBtn {
  font-weight: 300;
  font-size: 25px;
  color: #b7b7b7;
}
.btn-sign-in {
  display: flex;
  align-items: center;
  text-align: center;
}
@media (max-width: 467px) {
  .d-desctop {
    display: none;
  }
}
</style>
