<template >
  <div class="wrapper">
    <v-dialog v-model="dialog" max-width="540px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mx-2" :color="isActive" v-bind="attrs" v-on="on">
          <v-icon large>mdi-information-variant </v-icon>
        </v-btn>
      </template>
      <v-card class="dialogBox px-3 py-9">
          <div
            @click="dialog = false"
            class="dialogClose"
          >
            <v-icon>mdi-close </v-icon>
          </div>
        <h1 class="heading pb-0 text-center">
          Добро пожаловать в<br />
          Сервис Единого Входа!
        </h1>
        <v-tabs
          v-model="tab"
          class="my-2"
          background-color="white"
          fixed-tabs
          light
          :show-arrows="true"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="(item, index) in tabList" :key="index">
            {{ item.aboutTopText }}
          </v-tab>
        </v-tabs>
        <TabItem class="mt-7 tabWrapper" :tab="tab" :tabList="tabList" />
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TabItem from "@/components/TabItem.vue";

export default {
  components: {
    TabItem,
  },
  data() {
    return {
      dialog: false,
      tab: null,
      tabList: [
        {
          aboutTopText: "Как войти мне?",
          list: [
            {
              header: "Страница для пользователей Go Mobile",
              content: [
                {
                  link: "https://client.entrance.gomobile.ru/admin",
                },
              ],
            },
            {
              header: "Чтобы залогиниться как сотрудник:",
              content: [
                {
                  text: "Тыкнуть большую кнопку войти (если не тыкается, нажать комбинацию клавиш Ctrl+Shift+R)",
                  url: "image1",
                },
                {
                  text: "войти по своей рабочей google почте. (Это та, которая заканчивается на @gomobile.ru)",
                },
              ],
            },
            {
              header: "Создать вход для нового клиента",
              content: [
                {
                  text: "Тык на большую кнопку “Добавить”",
                  url: "image2",
                },
                {
                  text: "Придумайте имя и пароль (все это потом можно будет изменить)",
                },
              ],
            },
            {
              header: "Редактировать клиента",
              content: [
                {
                  text: "Тык на кнопочку рядом с именем твоего клиента",
                  url: "image3",
                },
              ],
            },
            {
              header: "Как посмотреть, что видит клиент?",
              content: [
                {
                  text: "Список подключенных к клиенту сервисов можно увидеть в колонке Сервисы напротив имени клиентов",
                  url: "image5",
                },
              ],
            },
            {
              header: "Как посмотреть, что видит клиент?",
              content: [
                {
                  text: "Тык на кнопку залогиниться (вы увидите то же самое, что увидит клиент, когда залогинится под своим именем - паролем)",
                  url: "image6",
                },
              ],
            },
          ],
        },
        {
          aboutTopText: "Как войти клиенту?",
          list: [
            {
              header: "Страница для клиентов находится тут",
              content: [
                {
                  link: "https://client.entrance.gomobile.ru",
                },
              ],
            },
            {
              header: "Авторизация",
              content: [
                {
                  text: "Ввести имя и пароль, который вы создали на предыдущих этапах",
                  url: "image7",
                },
                {
                  text: "Выбрать сервис, в который он хочет попасть (на данный момент есть возможность подключения только к сервису согласования креативов, в дальнейшем будут добавляться новые) и тыкнуть “Go”",
                  url: "image4",
                },
                {
                  text: "Все :)",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    isActive() {
      return this.dialog ? "primary" : "grey";
    },
  },
};
</script>
<style lang="scss">
.dialogBox {
  min-height: 650px;
  position: relative;
}
.dialogClose .v-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: #ff7323;
  }
}

.tabWrapper {
  max-height: 450px;
  padding: 0 25px;
  overflow-y: scroll;
}
@media screen and (max-width: 500px) {
  .heading {
    font-size: 20px;
  }
}
</style>