import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Admin.vue"),
  },
    {
    path: "/error404",
    name: "error404",
    component: () => import("../views/ErrorPage.vue"),
  },
  // {
  //   path: "*",
  //   redirect: '/error404' 
  // },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
