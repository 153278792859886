import axios from "axios";
const path = "/api/v1";
const get = async function(url: string, data: object | null = null) {
  let response;
  try {
    // @ts-ignore
    response = await axios({
      method: "GET",
      url: `${path}/${url}`,
      params: {
        ...data
      },
      withCredentials: true,
    });
  } catch (er) {
    console.log("Request error: ", er);
    return { status: false, data: er };
  }
  return response.data;
};
const post = async function(url: string, data: object | null = null) {
  let response;
  try {
    response = await axios.post(`${path}/${url}`, data, {
      withCredentials: true,
    });
  } catch (er) {
    console.log("Request error: ", er);
    return { status: false, data: er };
  }
  return response.data;
};
const put = async function(url: string, data: object | null = null) {
  let response;
  try {
    response = await axios.put(`${path}/${url}`, data, {
      withCredentials: true,
    });
  } catch (er) {
    console.log("Request error: ", er);
    return { status: false, data: er };
  }
  return response.data;
};
const deleteMet = async function(url: string, data: object | null = null) {
  let response;
  try {
    response = await axios({
      method: "DELETE",
      url: `${path}/${url}`,
      data: data,
      withCredentials: true,
    });
  } catch (er) {
    console.log("Request error: ", er);
    return { status: false, data: er };
  }
  return response.data;
};
export default {
  get,
  post,
  put,
  deleteMet,
};
