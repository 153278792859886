import Vue from "vue";
import Vuex from "vuex";
import api from "@/api/api";
Vue.use(Vuex);
const url = "https://gomobile.ru/promo/";
export default new Vuex.Store({
  state: {
    allServices: [
      {
        id: "dashboards",
        name: "Dashboards",
        description: `Визуализированные результаты вашей рекламной кампании`,
        link: "https://dashboards.gomobile.ru/view/id",
        image: `dash`,
      },
      {
        id: "creative",
        name: "Creative Approval System",
        description: `Управление креативами для вашей рекламной кампании`,
        link: "https://creative.gomobile.ru/",
        image: `creative`,
      },
      {
        id: "dataviz",
        name: "Go Data Visualization",
        description: `Визуализация данных`,
        link: "https://dataviz.gomobile.ru/",
        image: `visual`,
      },
    ],
    serviceList: [],
    adminServiceList: [
      {
        name: "Admins Dashboards",
        description: "Панель сотрудника сервиса Dashboards",
        link: "https://dashboards.gomobile.ru",
        image: `dash`,
      },
      {
        name: "Admins Creative App",
        description: "Панель сотрудника сервиса Creative",
        link: "https://creative.gomobile.ru/admin",
        image: `creative`,
      },
      {
        name: "Go Data Visualization",
        description: "Визуализация данных",
        link: "https://dataviz.gomobile.ru/",
        image: `visual`,
      },
    ],
    loginRules: [
      (v: string) => !!v || "Обязательно поле!",
      (v: string) => v.length >= 7 || "Минимальная длина 7 символов!",
      (v: string) =>
        /^[a-zA-Z0-9][a-zA-Z0-9-_\.]{1,20}$/.test(v) || "Недопустимые символы!",
    ],
    passwordRules: [
      (v: string) => !!v || "Обязательно поле!",
      (v: string) => v.length >= 7 || "Минимальная длина 7 символов!",
    ],
    isAuth: false,
    users: [],
    users_const: [],
    adminIsLogin: false,
    clientLogined: false,
    testList: ["https://creative.gomobile.ru/"],
  },
  mutations: {
    SET_USERS: (state, users) => {
      //@ts-ignore
      state.users = users.sort(function(a, b) {
        if (a.id > b.id) {
          return 1;
        }
        if (a.id < b.id) {
          return -1;
        }
        return 0;
      });
      state.users_const = state.users;
    },
    SET_AUTH: (state, boolka) => {
      state.isAuth = boolka;
    },

    SET_LOGIN_ADMIN: (state, boolka) => {
      state.adminIsLogin = boolka;
    },
    SET_CLIENT_LOGIN: (state, boolka) => {
      state.clientLogined = boolka;
    },
    SET_CLIENT_SERVICES: (state, list) => {
      if (list == "local") {
        //@ts-ignore
        state.serviceList = state.allServices;
        return;
      }
      //@ts-ignore
      const str = list.map((el) => {
        return el.slice(0, el.indexOf(".gomobile.ru")).split("//")[1];
      });
      const newList = state.allServices.filter((el) => {
        return str.includes(el.id);
      });
      //@ts-ignore
      state.serviceList = newList;
      // console.log("newList", newList);
    },
    SET_PIN: (state, item) => {
      const users = state.users.filter((el) => {
        //@ts-ignore
        return el.id != item.id;
      });
      //@ts-ignore
      users.unshift(item);
      state.users = users;
    },
    SET_FILTER: (state, id) => {
      const list = ["creative", "dashboards", "dataviz"];
        state.users = state.users_const.filter((el) => {
          //@ts-ignore
          if (el.serviceLinks) {
            //@ts-ignore
            const hel = el.serviceLinks.filter((elem) => {
              if (elem.includes(list[id])) return elem;
            });
            if (hel.length > 0) return el;
          }
        });
      if (id == 3) {
        state.users = state.users_const.filter((el) => {
          //@ts-ignore
          if (!el.serviceLinks) return el;
        });
      }
      if (id == 4) {
        state.users = state.users_const
      }
    },
  },
  actions: {
    GET_LOGINED({ commit }, data) {
      if (
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("192.168")
      ) {
        commit("SET_CLIENT_LOGIN", true);
        return;
      }
      return api.get("islogged").then((el) => {
        if (el.status) {
          commit("SET_CLIENT_LOGIN", true);
          return el.status;
        }
      });
    },
    GET_SERVICE({ commit }) {
      if (
        window.location.hostname.includes("localhost") ||
        window.location.hostname.includes("192.168")
      ) {
        // commit("SET_CLIENT_SERVICES", "local");
        commit("SET_CLIENT_SERVICES", [
          "https://creative.gomobile.ru/",
          "https://dashboards.gomobile.ru/view/",
          "https://dataviz.gomobile.ru/",
        ]);

        return;
      }
      return api.get("service").then((el) => {
        commit("SET_CLIENT_SERVICES", el.data);
        return el;
      });
    },
    GET_USERS_FROM_API({ commit }) {
      return api.get("internal/client").then((el) => {
        if (el.status == false) {
          return false; //loader off
        }
        if (Array.isArray(el.data)) {
          commit("SET_USERS", el.data);
          return true; //loader off
        }
      });
    },
    CREATE_USER({ commit }, data) {
      return api.post("internal/client", data).then((el) => {
        return el;
      });
    },
    LOG_IN({ commit }, data) {
      return api.post("pass/password", data).then((el) => {
        return el;
      });
    },
    LOG_OUT({ commit }) {
      return api.get("signout").then((el) => {
        return el;
      });
    },
    LOGIN_AS_CLIENT({ commit }, data) {
      return api.get("internal/client", data).then((el) => {
        return el;
      });
    },
    UPDATE_USER({ commit }, data) {
      return api.put("internal/client", data).then((el) => {
        return el;
      });
    },
    DELETE_CLIENT({ commit }, data) {
      return api.deleteMet("internal/client", data).then((el) => {
        return el;
      });
    },
  },
  getters: {
    getServices(state) {
      return state.serviceList;
    },
    getAdminServiceList(state) {
      return state.adminServiceList;
    },
    GET_USERS(state) {
      return state.users;
    },
    GET_LOGIN_VALID(state) {
      return state.loginRules;
    },
    GET_PASS_VALID(state) {
      return state.passwordRules;
    },
    ///????
    GET_ISAUTH(state) {
      return state.isAuth;
    },

    ADMIN_ISLOGIN(state) {
      return state.adminIsLogin;
    },
    GET_CLIENT_LOGINED(state) {
      return state.clientLogined;
    },
  },
});
