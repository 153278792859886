<template>
  <div class="logoutButton" @click="logout">
    <v-icon> mdi-logout </v-icon>
    <span class="displayDesctop">Выйти</span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["LOG_OUT"]),
    logout() {
      this.LOG_OUT().then((el) => {
        // localStorage.removeItem("login");
        document.cookie = "client_login=false";
        this.$store.commit("SET_COOKIE_AUTH", false);
        location.reload(true);
      });
    },
  },
};
</script>
<style lang="scss">
.logoutButton {
  cursor: pointer;
  transition: 0.4s;
  line-height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
  span,
  i {
    color: #bdc3c7 !important;
  }
}
</style>
