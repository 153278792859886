<template>
  <div class="navWrapper">

    <div class="navbarGrad">


      <div v-if="isAdmin" class="displayDesctop">
        <router-link to="/" v-if="$store.getters.ADMIN_ISLOGIN" tag="a" class="homeLink homeRoute">
          <v-icon> mdi-chevron-left </v-icon>
          <span class="linkText">Главная</span>
        </router-link>
        <!--  <LoginUser /> -->
      </div>
      <div v-if="!isAdmin && isgoogleLogined" class="displayDesctop">
        <router-link to="/admin" tag="a" class="homeLink homeRoute">
          <span class="linkText">Админ</span>
          <v-icon> mdi-chevron-right </v-icon>
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <div class="header-title">GoMobile's Services</div>
      <v-spacer></v-spacer>
      <v-toolbar-items class="btnLogout">
        <div v-if="!isAdmin && $store.getters.GET_CLIENT_LOGINED" class="logoutClient">
          <LogoutUser />
        </div>
      </v-toolbar-items>
      <v-toolbar-items v-if="isAdmin" class="btnLogout displayDesctop d-flex align-center">
        <Helper v-if="$store.getters.ADMIN_ISLOGIN" />
        <GoogleSinginBtn v-if="$store.getters.ADMIN_ISLOGIN" />
      </v-toolbar-items>
    </div>
  </div>
</template>

<script>
import GoogleSinginBtn from "@/components/GoogleSinginBtn.vue";
import LogoutUser from "@/components/LogoutUser";
import LoginUser from "@/components/LoginUser";
import Helper from "@/components/Helper";
import { mapGetters } from "vuex";
export default {
  components: {
    GoogleSinginBtn,
    LogoutUser,
    LoginUser,
    Helper,
  },
  computed: {},
  data() {
    return {
      isAuth: false,
      isgoogleLogined: false,
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    greetingUser() {
      if (localStorage.getItem("user")) {
        return `Привет, ${localStorage.getItem("user")}`;
      } else {
        return `Salut!`;
      }
    },
    isAdmin() {
      if (this.$route.path == "/admin") {
        return true;
      }
    },
  },
  methods: {
    initialize() {
      const clientKey = this.getCookie("client_key");
      const clientId = this.getCookie("client_id");
      const googleLogined = this.getCookie("auth");
      if (googleLogined && googleLogined != "false") {
        this.isgoogleLogined = true;
      }
      if (clientKey && clientId) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    },
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          "(?:^|; )" +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/navigation.scss";
</style>
